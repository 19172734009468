import { DefaultTheme } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const themeHealhyCareOnline: DefaultTheme = {
  font: {
    fontFamily: '"IBM Plex Sans", sans-serif',
  },
  images: {
    logo: '/images/healthycareonline_logo.svg',
    iconCheck: '/icons/cmn_check_active_eathappyhealthy.svg',
    iconFilledInverse: '/icons/cmn_filled_inverse_blue.svg',
    iconNavOpen: '/icons/nav_open_healthydietfitness.svg',
    iconNavClose: '/icons/nav_close_healthydietfitness.svg',
  },
  sizes: {
    desktop: '1280px',
    tablet: '768px',
  },
  colors: {
    primary0: '#F2FFFB',
    primary5: '#E8FBF5',
    primary10: '#CFF0E6',
    primary20: '#A7DFCD',
    primary30: '#75C6AC',
    primary40: '#43A687',
    primary50: '#1E8565',
    primary60: '#086A4B',
    primary70: '#00573B',
    primary80: '#004C34',
    primary90: '#004731',
    primary100: '#004731',

    secondary0: '#F0F8FF',
    secondary5: '#D7EDFD',
    secondary10: '#BFE1FC',
    secondary20: '#8ECAF9',
    secondary30: '#54A8EA',
    secondary40: '#1B6BAC',
    secondary50: '#0F4C81',
    secondary60: '#07335A',
    secondary70: '#03223E',
    secondary80: '#01192F',
    secondary90: '#01192F',
    secondary100: '#01192F',

    black0: '#FFFFFF',
    black5: '#F5F5F5',
    black10: '#E8E8E8',
    black15: '#DBDBDB',
    black20: '#C2C2C2',
    black30: '#A8A8A8',
    black40: '#8F8F8F',
    black50: '#757575',
    black60: '#5C5C5C',
    black70: '#5C5C5C',
    black80: '#292929',

    red0: '#FFE8EA',
    red5: '#FED0D4',
    red10: '#FDB8BF',
    red20: '#F98994',
    red30: '#F45F6E',
    red40: '#EC3E4F',
    red50: '#E22336',
    red60: '#D41124',
    red70: '#C30417',
    red80: '#AF0011',
    red90: '#97000F',
    red100: '#80000D',

    orange0: '#FFF2EE',
    orange5: '#FEE3D9',
    orange10: '#FED3C5',
    orange20: '#FBB49C',
    orange30: '#F89878',
    orange40: '#F38059',
    orange50: '#EC6C42',
    orange60: '#E45D2F',
    orange70: '#D95022',
    orange80: '#CC4519',
    orange90: '#BD3D12',
    orange100: '#AD360E',

    blue0: '#F0F8FF',
    blue5: '#D7EDFD',
    blue10: '#BFE1FC',
    blue20: '#8ECAF9',
    blue30: '#54A8EA',
    blue40: '#1B6BAC',
    blue50: '#0F4C81',
    blue60: '#07335A',
    blue70: '#03223E',
    blue80: '#01192F',

    pink0: '#FFEFF4',
    pink5: '#FED4E0',
    pink10: '#FCB9CD',
    pink20: '#F887A8',
    pink30: '#F05B87',
    pink40: '#E6376B',
    pink50: '#D81B54',
    pink60: '#C50D48',
    pink70: '#AE053F',
    pink80: '#920136',
  },
};
